var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"body"}},[_vm._m(0),_c('div',{attrs:{"id":"wx_tit"}},[_vm._m(1),_vm._m(2),_c('div',{attrs:{"id":"wx_tit_main"}},[_c('div',{attrs:{"id":"wx_tit_main_box"}},[_c('icon-svg',{staticClass:"iconclas",attrs:{"iconClass":"bangong0"}}),_c('p',[_vm._v("实时审批")])],1),_c('div',{attrs:{"id":"wx_tit_main_box"}},[_c('icon-svg',{staticClass:"iconclas",attrs:{"iconClass":"fukuandanguanli"}}),_c('p',[_vm._v("在线报销")])],1),_c('div',{attrs:{"id":"wx_tit_main_box"}},[_c('icon-svg',{staticClass:"iconclas",attrs:{"iconClass":"fuwu"}}),_c('p',[_vm._v("客户管理")])],1),_c('div',{attrs:{"id":"wx_tit_main_box"}},[_c('icon-svg',{staticClass:"iconclas",attrs:{"iconClass":"qianyue"}}),_c('p',[_vm._v("合同管理")])],1),_c('div',{attrs:{"id":"wx_tit_main_box"}},[_c('icon-svg',{staticClass:"iconclas",attrs:{"iconClass":"ico_shebeiguanli_shebeiweixiujingzhunyixue"}}),_c('p',[_vm._v("项目管理")])],1)]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)]),_c('div',{attrs:{"id":"wshopbox"}},[_c('p',{attrs:{"id":"on_wx"}},[_vm._v("限时免费体验大总管OA")]),_c('p',{attrs:{"id":"tw_wx"}},[_vm._v("你手机里的移动办公室，在线办公")]),_c('p',{attrs:{"id":"tw_wx"}},[_vm._v("移动办公 | 在线审批 | 在线考勤 | 在线报销")]),_c('p',{attrs:{"id":"tw_wx"}},[_vm._v("￥0")]),_c('button',{attrs:{"type":"button"},on:{"click":_vm.tyfun}},[_vm._v("免费体验")])]),_c('footers')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"wx-banner"}},[_c('img',{attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/banner03.jpg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"wx_tit_header"}},[_c('p',[_vm._v("OA办公APP专属功能，办公尽在掌中")]),_c('span',[_vm._v("大总管OA办公APP可实现移动办公，实时审批，在线报销，实时客户管理，合同审批真正让您实现移动办公")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"wx-img"}},[_c('img',{attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/phoneoa.jpg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"600PX","padding-top":"10%"},attrs:{"id":"WX-shop"}},[_c('img',{attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/phonekh.jpg"}}),_c('div',{attrs:{"id":"WX-shop_NAME"}},[_c('p',{attrs:{"id":"Ti"}},[_vm._v("客户管理")]),_c('span',[_vm._v("能实时统计出您对客户跟踪的情况，哪些客户能否成交，以及跟踪的情况，让您对客户了如指掌")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"WX-shop"}},[_c('img',{attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/phonesp.jpg"}}),_c('div',{attrs:{"id":"WX-shop_NAME"}},[_c('p',{attrs:{"id":"Ti"}},[_vm._v("实时审批")]),_c('span',[_vm._v("针对移动办公的特点我们把需要您审批的单据自动帮您汇集在待办模块下，让您能打开手机随时随地进行业务单据的审批让您对整个公司的报销，工作量，等业务模块实时掌握")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"600PX","margin-top":"-140px"},attrs:{"id":"WX-shop"}},[_c('img',{attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/phoneht.jpg"}}),_c('div',{attrs:{"id":"WX-shop_NAME"}},[_c('p',{attrs:{"id":"Ti"}},[_vm._v("合同管理")]),_c('span',[_vm._v("通过合同管理模块您可以实时查看到原始合同的复印件，把您合同的重要条款给您自动摘录，实时提醒您哪些合同需要收款，哪些已超过收款期限，把您合同的应收款和应付款实时掌握")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"paybgc"}},[_c('i',{staticClass:"el-icon-circle-check"}),_c('p',[_vm._v("免费开通专属手机APP移动办公")]),_c('span',[_vm._v("让您实现移动办公"),_c('br'),_vm._v(" 实时查看合同管理、客户管理、报销信息并能在线审批单据实现办公业务的移动无纸化操作")])])
}]

export { render, staticRenderFns }