<template>
	<div id="body">
		<div id="wx-banner">
			<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/banner03.jpg" >
		</div>
		<div id="wx_tit">
			<div id="wx_tit_header">
				<p>OA办公APP专属功能，办公尽在掌中</p>
				<span>大总管OA办公APP可实现移动办公，实时审批，在线报销，实时客户管理，合同审批真正让您实现移动办公</span>
			</div>
			<div id="wx-img">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/phoneoa.jpg" >
			</div>
			<div id="wx_tit_main">
				<div id="wx_tit_main_box">
					<icon-svg class='iconclas' iconClass='bangong0'></icon-svg>
					<p>实时审批</p>
				</div>
				<div id="wx_tit_main_box">
					<icon-svg class='iconclas' iconClass='fukuandanguanli'></icon-svg>
					<p>在线报销</p>
				</div>
				<div id="wx_tit_main_box">
					<icon-svg class='iconclas' iconClass='fuwu'></icon-svg>
					<p>客户管理</p>
				</div>
				<div id="wx_tit_main_box">
					<icon-svg class='iconclas' iconClass='qianyue'></icon-svg>
					<p>合同管理</p>
				</div>
				<div id="wx_tit_main_box">
					<icon-svg class='iconclas' iconClass='ico_shebeiguanli_shebeiweixiujingzhunyixue'></icon-svg>
					<p>项目管理</p>
				</div>
			</div>
			<div id="WX-shop" style="height: 600PX;padding-top: 10%;">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/phonekh.jpg" >
				<div id="WX-shop_NAME">
					<!-- <p id="sub">微信店铺</p> -->
					<p id="Ti">客户管理</p>
					<span>能实时统计出您对客户跟踪的情况，哪些客户能否成交，以及跟踪的情况，让您对客户了如指掌</span>
				</div>
			</div>
			<div id="WX-shop">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/phonesp.jpg" >
				<div id="WX-shop_NAME">
					<p id="Ti">实时审批</p>
					<span>针对移动办公的特点我们把需要您审批的单据自动帮您汇集在待办模块下，让您能打开手机随时随地进行业务单据的审批让您对整个公司的报销，工作量，等业务模块实时掌握</span>
				</div>
			</div>
			<div id="WX-shop" style="height: 600PX;margin-top: -140px;">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/phoneht.jpg" >
				<div id="WX-shop_NAME">
					<p id="Ti">合同管理</p>
					<span>通过合同管理模块您可以实时查看到原始合同的复印件，把您合同的重要条款给您自动摘录，实时提醒您哪些合同需要收款，哪些已超过收款期限，把您合同的应收款和应付款实时掌握</span>
				</div>
			</div>
			<div id="paybgc">
				<i class="el-icon-circle-check"></i>
				<p>免费开通专属手机APP移动办公</p>
				<span>让您实现移动办公</br>
							实时查看合同管理、客户管理、报销信息并能在线审批单据实现办公业务的移动无纸化操作</span>
			</div>
		</div>
		<div id="wshopbox">
			<p id="on_wx">限时免费体验大总管OA</p>
			<p id="tw_wx">你手机里的移动办公室，在线办公</p>
			<p id="tw_wx">移动办公 | 在线审批 | 在线考勤 | 在线报销</p>
			<p id="tw_wx">￥0</p>
			<button @click="tyfun" type="button">免费体验</button>
		</div>
		<footers></footers>
	</div>
</template>

<script>
	import footers from '../FOOTER.vue'
	export default{
		components: {
			footers
		},
		methods:{
			tyfun(){
				this.$router.push('/downAPP')
				
			}
			
		}
		}
</script>

<style scoped="scoped" lang="less">
	#wx-banner{
		width: 100%;
		height: 200px;
		img{
			width: 100%;
			height: 100%;
		}
	}
	#wx_tit{
		width: 100%;
		
		// background-color: #FF0000;
		text-align: center;
		padding-top: 5%;
		#wx_tit_header{
			width: 100%;
			height: 140px;
			p{
				font-size: 22px;
			}
			span{
				display: block;
				width: 90%;
				margin-left: 5%;
				font-size: 14px;
				color: #6f6f6f;
			}
		}
		#wx_tit_main{
			width: 100%;
			height: 200px;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: center;
			#wx_tit_main_box{
				width: 32%;
				height: 49%;
				margin-left: 1%;
				.iconclas{
					font-size: 40px;
				}
				p{
					font-size: 18px;
					font-weight: bold;
				}
			}
		}
		#WX-shop{
			width: 100%;
			height: 700px;
			img{
				max-width: 100%;
				max-height: 80%;
			}
			#WX-shop_NAME{
				#sub{
					font-size: 20px;
					margin-top: 3%;
				}
				#Ti{
					font-size: 24px;
					margin-top: 10%;
				}
				span{
					display: block;
					font-size: 14px;
					width: 80%;
					margin-left: 10%;
				}
			}
		}
		#paybgc{
			width: 100%;
			height: 300px;
			background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Phonepay.jpg');
			background-size: 100% 100%;
			color: #FFFFFF;
			text-align: center;
			padding-top: 20%;
			i{
				font-size: 50px;
				margin-bottom: 1rem;
			}
			p{
				font-size: 24px;
			}
			span{
				display: block;
				width: 80%;
				margin-left: 10%;
				font-size: 14px;
			}
		}
	}
	#wshopbox{
		width: 100%;
		height: 300px;
		background-color: #eaeaea;
		text-align: center;
		padding-top: 15%;
		#on_wx{
			font-size: 28px;
			color: #fd3f31;
		}
		#tw_wx{
			font-size: 17px;
		}
		button{
			width: 40%;
			height: 50px;
			background-color: #fd3f31;
			border-radius: 5px;
			color: #FFFFFF;
		}
	}
</style>
